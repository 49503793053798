<template>
  <v-container fill-height fluid text-xs-center>
    <v-card class="fill-height container--fluid" width="100vw" flat outlined>
      <v-data-table
        :headers="headers"
        :items="specialties"
        :items-per-page="-1"
        :search="searchSpecialty"
        :loading="loadingData"
        item-key="id"
        no-data-text="No hay datos para mostrar..."
        loading-text="Cargando los datos, por favor espere!"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: false,
          disableItemsPerPage: false,
          itemsPerPageAllText: 'Todas',
          itemsPerPageText: 'Filas por página:',
        }"
      >
        <template v-slot:top>
          <v-toolbar flat class="align-center">
            <v-toolbar-title class="text-subtitle-1 text-md-h5"
              >Especialidades</v-toolbar-title
            >
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-responsive
              max-width="300"
              height="45"
              class="hidden-sm-and-down"
            >
              <v-text-field
                id="findtext-specialty"
                label="Buscar.."
                name="findspecialty"
                outlined
                dense
                single-line
                clearable
                background-color="white"
                hide-details
                v-model="searchSpecialty"
                class="white--text mt-0 pt-0 mr-2"
                prepend-inner-icon="mdi-magnify"
              >
              </v-text-field>
            </v-responsive>
            <v-dialog
              :fullscreen="$vuetify.breakpoint.smAndDown"
              v-model="dialog"
              max-width="650px"
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :small="$vuetify.breakpoint.smAndDown"
                  color="blue darken-1"
                  dark
                  depressed
                  elevation="0"
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="newItem"
                >
                  Adicionar
                </v-btn>
              </template>
              <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-title class="text-h6 grey lighten-2">
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="8">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Nombre - Español"
                            :rules="nameRules"
                            required
                          >
                          </v-text-field>

                          <v-text-field
                            v-model="editedItem.name_english"
                            label="Nombre - Inglés"
                            :rules="nameRules"
                            required
                          >
                          </v-text-field>
                        </v-col>
                        <v-col class="pl-2 ml-2">
                          <v-row justify="center">
                            <v-img
                              :src="
                                imagePreview != null
                                  ? imagePreview
                                  : require('@/assets/images/no-image.jpg')
                              "
                              :lazy-src="
                                require('@/assets/images/no-image.jpg')
                              "
                              max-height="150"
                              max-width="150"
                              aspect-ratio="1"
                              class="white--text align-center justify-center elevation-2"
                            >
                            </v-img>
                          </v-row>
                          <v-row>
                            <v-file-input
                              v-model="imageFile"
                              accept="image/png, image/jpeg, image/bmp, image/svg"
                              show-size
                              clearable
                              label="Cargar/Cambiar avatar"
                              placeholder="Archivo de imágen"
                              prepend-icon="mdi-camera"
                              @change="changePreviewImage()"
                            ></v-file-input>
                          </v-row>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-textarea
                            v-model="editedItem.description"
                            label="Descripción - Español"
                            :rules="nameRules"
                            no-resize
                            rows="4"
                            dense
                            counter
                            required
                          >
                          </v-textarea>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-textarea
                            v-model="editedItem.description_english"
                            label="Descripción - Inglés"
                            :rules="nameRules"
                            no-resize
                            rows="4"
                            dense
                            counter
                            required
                          >
                          </v-textarea>
                        </v-col>
                        <v-col cols="12">
                          <v-checkbox
                            dense
                            v-model="editedItem.enabled"
                            label="Habilitada?"
                            :rules="[
                              (v) =>
                                !!v ||
                                'Debes de marcar Habilitada para continuar!',
                            ]"
                            required
                          ></v-checkbox>
                        </v-col>
                        <!-- <v-col cols="12">
                          <v-btn color="primary" rounded outlined>
                            <v-icon left> mdi-translate </v-icon>
                            Traducción
                          </v-btn>
                        </v-col> -->
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="!valid"
                      color="primary"
                      text
                      @click="save"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 headline"
                  >¿Est&aacute; seguro que desea eliminar este elemento?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >Proceder</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogError" persistent max-width="500">
              <v-card>
                <v-card-title class="text-h6 primary lighten-2">
                  <span class="headline">Error de Validación</span>
                </v-card-title>

                <v-card-text>
                  <div class="text-h6 pa-12">{{ messageError }}!</div>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn text @click.stop="closeDialogError"> Cerrar </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.enabled="{ item }">
          <v-simple-checkbox
            v-model="item.enabled"
            disabled
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <span class="text-h5">No hay registros en el listado!</span>
          <br />
          <v-btn color="primary" @click="getSpecialties"> Recargar </v-btn>
        </template>
      </v-data-table>
      <v-snackbar v-model="snackBar" :timeout="2000">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" icon @click="snackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import specialtiesService from "@/providers/SpecialtiesService";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
  name: "SpecialtiesComponent",
  data: () => ({
    snackBar: false,
    snackText: "",
    valid: true,
    selectTranslation: null,
    selectKeywords: null,
    searchSpecialty: "",
    loadingData: false,
    dialog: false,
    dialogDelete: false,
    dialogError: false,
    messageError: "",
    imageFile: null,
    imagePreview: null,
    selectedImageIndex: -1,
    specialties: [],
    headers: [
      { text: "Id", value: "id", align: " d-none" }, // para ocultar la columna Id.
      {
        text: "Nombre",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Activo", value: "enabled", align: "center", width: 100 },
      { text: "Acciones", value: "actions", width: 100, sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      id: 0,
      name: "",
      name_english: "",
      description: "",
      description_english: "",
      translation_id: null,
      keyword_id: null,
      enabled: false,
      pictures: [],
    },
    defaultItem: {
      id: 0,
      name: "",
      name_english: "",
      description: "",
      description_english: "",
      translation_id: null,
      keyword_id: null,
      enabled: false,
      pictures: [],
    },
    nameRules: [
      (v) => !!v || "El Nombre es obligatorio!",
      // v => (v && v.length >= 10) || 'El Nombre debe tener mas de un caracter!',
    ],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nueva Especialidad"
        : "Editar Especialidad";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    getSpecialties() {
      // carga los datos desde el proveedor de datos.
      this.loadingData = true;
      specialtiesService.getViewList().then((record) => {
        this.specialties = record.value;
        // console.log("Especialidades: ", this.specialties);
        this.loadingData = false;
      });
    },
    newItem() {
      this.imagePreview = null;
      this.imageFile = null;
      this.selectedImageIndex = -1;
      this.valid = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    editItem(item) {
      this.editedIndex = this.specialties.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.pictures.length > 0) {
        this.imagePreview = this.editedItem.pictures[0].url;
        this.selectedImageIndex = this.editedItem.pictures[0].id;
      } else {
        this.imagePreview = null;
        this.selectedImageIndex = -1;
      }
      this.imageFile = null;
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.specialties.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.pictures.length > 0) {
        this.selectedImageIndex = this.editedItem.pictures[0].id;
      } else {
        this.selectedImageIndex = -1;
      }
      // console.log('Item to delete: %s %s ', this.editedItem.id, this.editedItem);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      specialtiesService.deleteRecord(this.editedItem.id).then((response) => {
        if (response.success) {
          this.specialties.splice(this.editedIndex, 1);
          this.snackText = "Registro eliminado con éxito.";
        } else {
          this.snackText = "Un error impidió eliminar el registro!";
        }
      });
      this.closeDelete();
      this.snackBar = true;
      /*console.log('this.editedItem.id: ', this.editedItem.id);
                let recordIndex = this.editedItem.id;
                specialtiesService.deleteImage(this.editedItem.id, this.selectedImageIndex).then(
                    response => {
                        console.log('Response', response);
                        // if (response.success) {
                        // console.log('Imagen eliminada con exito...');
                        // 2- Elimina el record seleccioando desde la BD.
                        specialtiesService.deleteRecord(recordIndex).then(
                            resp => {
                                console.log('Resp:', resp);
                                this.specialties.splice(this.editedIndex, 1);
                            }
                        )
                        this.snackText = 'Registro eliminado con éxito.';
                    }
                );
                this.closeDelete();
                this.snackBar = true;*/
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.imagePreview = null;
        this.selectedImageIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.imagePreview == null) {
        this.messageError =
          "El formulario no contiene imagen asociada. Por favor agrégale una!!";
        this.dialogError = true;
        return;
      }
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        if (this.editedIndex > -1) {
          Object.assign(this.specialties[this.editedIndex], this.editedItem);
          // specialtiesService.updateRecord(this.editedItem.id, this.editedItem);
          specialtiesService
            .updateRecord(
              this.editedItem,
              this.selectedImageIndex,
              this.imageFile
            )
            .then((response) => {
              if (response.success) {
                this.getSpecialties();
              }
            });
        } else {
          this.specialties.push(this.editedItem);
          // specialtiesService.addRecord(this.editedItem);
          specialtiesService
            .addRecord(
              this.editedItem,
              -1 /*this.selectedImageIndex*/,
              this.imageFile
            )
            .then((response) => {
              if (response.success) {
                this.getSpecialties();
              }
            });
        }
        this.close();
        this.snackText = "Datos guardados con éxito...";
        this.snackBar = true;
      }
    },
    changePreviewImage() {
      if (this.imageFile) {
        let reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result;
        };
        reader.readAsDataURL(this.imageFile);
        if (this.editedItem.pictures.length === 0) {
          let imagePicture = {
            id: 2,
            module_id: 2,
            url: this.imageFile.name,
          };
          this.editedItem.pictures.push(imagePicture);
        }
        this.snackText = "Imágen de perfil cargada con éxito...";
        this.snackBar = true;
      }
    },
    closeDialogError() {
      this.dialogError = false;
      this.messageError = "";
    },
  },
  mounted() {
    this.specialties = [];
    this.getSpecialties();
    changeInformationSEO();
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}
</style>
